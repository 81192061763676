let origin = window.ORIGIN;
//众创空间列表
const createWorkUrl = `${origin}/gateway/hc-external/makerSpace/getApplyList`;

//众创空间申请
const applyCreateWorkList = `${origin}/gateway/hc-external/makerSpace/doApply`;
//详情
const createWorkDetail = `${origin}/gateway/hc-space/space/detail`;
//众创空间列表
const workAreaListUrl = `${origin}/gateway/hc-space/space/list`;
//状态变更
const updateStatusUrl = `${origin}/gateway/hc-space/space/update`;
export {
  createWorkUrl,
  applyCreateWorkList,
  createWorkDetail,
  workAreaListUrl,
  updateStatusUrl,
};
